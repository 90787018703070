import * as React from 'react';
import Layout from "../components/layout";
import { StaticImage } from 'gatsby-plugin-image';

const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page">
      <p>Welcome to my site.</p>
      <StaticImage src="../images/me.jpg" alt="Me!" />
    </Layout>
  )
}

export default IndexPage;
